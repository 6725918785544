<div class="base-paddings">
    <ng-container *ngIf="serviceSheetId">
        <ng-container *ngIf="serviceSheet; else loading">

            <!-- Fab -->
            <app-custom-fab [primaryFab]="primaryFab" [secondaryFab]="secondaryFab" (fabAction)="onFabAction($event)"></app-custom-fab>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">

                <!-- Left/top info pane -->
                <div fxFlex.gt-sm="30" class="contrast-bg" fxLayout="column">
                    <!-- Bike details -->
                    <app-bike-card [bikeId]="serviceSheet.bike"
                                class="pb1 link-action"
                                cardstyle="minimal">
                    </app-bike-card>

                    <!-- Stopwatch -->
                    <div class="pb2 px2" *ngIf="!serviceSheet.closed && userService.getCustomConfig().module_bike_workshop_timer_enabled()">
                        <div>
                            <!-- Start stopwatch -->
                            <button fxFill mat-button color="primary" *ngIf="!timerObs$"
                                (click)="startTimeTracker()"
                                style="padding: 10px;">
                                <mat-icon>play_arrow</mat-icon>
                                <span *ngIf="!totalTrackedTime">{{ 'START_TIME_TRACKING' | translate | uppercase }}</span>
                                <span *ngIf="totalTrackedTime">{{ totalTrackedTimeHms }}</span>

                            </button>
                            <!-- Stop btn -->
                            <button fxFill mat-flat-button color="accent" *ngIf="timerObs$"
                                (click)="stopTimeTracker()"
                                style="padding: 10px;">
                                <mat-icon color="warn">stop</mat-icon>
                                {{ totalTrackedTimeHms }}
                            </button>
                        </div>
                        <!-- <span *ngIf="totalTrackedTimeLiteral">{{ 'TOTAL' | translate }}: {{ totalTrackedTimeLiteral }}</span> -->
                    </div>
                    <div class="pb2 px2" *ngIf="serviceSheet.closed && userService.getCustomConfig().module_bike_workshop_timer_enabled()">
                      <span *ngIf="totalTrackedTimeHms">{{ 'TOTAL_WORK_TIME' | translate }}: {{ totalTrackedTimeHms }}</span>
                    </div>

                    <!-- Checkin status -->
                    <p class="px2i">
<!--                      &lt;!&ndash; TODO: on the future, mov ethis to the app bike card? also when the bike status is part of the bike and no the bss &ndash;&gt;-->
<!--                      <mat-button-toggle-group name="bikeCheckinStatus" id="bikeCheckinStatusToggles" (change)="changeBikeCheckinStatus($event)">-->
<!--                        <mat-button-toggle value="ret" [checked]="serviceSheet.checkInStatus == 'ret'">{{ 'PENDING_SCHEDULED_PICK_UP' | translate }}</mat-button-toggle>-->
<!--                        <mat-button-toggle value="cpe" [checked]="serviceSheet.checkInStatus == 'cpe'">{{ 'PENDING_CHECK_IN' | translate }}</mat-button-toggle>-->
<!--                        <mat-button-toggle value="cin" [checked]="serviceSheet.checkInStatus == 'cin'">{{ 'CHECKED_IN' | translate }}</mat-button-toggle>-->
<!--                        <mat-button-toggle value="cou" [checked]="serviceSheet.checkInStatus == 'cou'">{{ 'CHECKED_OUT' | translate }}</mat-button-toggle>-->
<!--                      </mat-button-toggle-group>-->

                      <!-- TODO: on the future, mov ethis to the app bike card? also when the bike status is part of the bike and no the bss -->
                      <mat-form-field class="w100">
                        <mat-label>{{ 'CHECK_IN_STATUS' | translate }}</mat-label>
                        <mat-select [(value)]="serviceSheet.checkInStatus" (selectionChange)="changeBikeCheckinStatus($event)">
                          <mat-select-trigger>
                            <span fxFlex="row" fxLayoutAlign="start center" class="{{ getCheckInStatusSelectTriggerIconAndText().class }}"><mat-icon>{{ getCheckInStatusSelectTriggerIconAndText().icon }}</mat-icon> {{ getCheckInStatusSelectTriggerIconAndText().texti18n | translate }}</span>
                          </mat-select-trigger>

                          <mat-option value="ret"><mat-icon>airport_shuttle</mat-icon> {{ 'PENDING_SCHEDULED_PICK_UP' | translate }}</mat-option>
                          <mat-option value="cpe"><mat-icon>event_upcoming</mat-icon> {{ 'PENDING_CHECK_IN' | translate }}</mat-option>
                          <mat-option value="cin"><mat-icon>build</mat-icon> {{ 'CHECKED_IN' | translate }}</mat-option>
                          <mat-option value="cou"><mat-icon>check_circle</mat-icon> {{ 'CHECKED_OUT' | translate }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </p>

                    <p style="text-align: center" *ngIf="serviceSheet.checkInStatus == 'cpe' || serviceSheet.checkInStatus == 'ret'">
                      <!-- TODO: remove app-inline-here, and just use directly material calendar date picker -->
<!--                      <ng-container *ngIf="serviceSheet.checkInScheduledDt">-->
<!--                        <span [satPopoverAnchor]="pdc" (click)="pdc.toggle()" [ngClass]="{'text-danger': isPastDate(serviceSheet.checkInScheduledDt)}">{{ 'CHECK_IN_SCHEDULED_AT' | translate }}: {{ serviceSheet.checkInScheduledDt | date }}</span>-->
<!--                      </ng-container>-->
<!--                      <ng-container *ngIf="!serviceSheet.checkInScheduledDt">-->
<!--                        <span [satPopoverAnchor]="pdc" (click)="pdc.toggle()" class="muted">{{ 'ADD_ESTIMATED_CHECK_IN_DATE' | translate }}</span>-->
<!--                      </ng-container>-->

<!--                      <sat-popover #pdc hasBackdrop xAlign="start" yAlign="start" (closed)="estimatedCheckinDateChange($event)">-->
<!--                        <app-edit-input type="datetime" [value]="serviceSheet.checkInScheduledDt" title="{{ 'CHECK_IN_SCHEDULED_AT' | translate }}"></app-edit-input>-->
<!--                      </sat-popover>-->
                      <app-inline-edit *ngIf="serviceSheet.checkInStatus == 'cpe'" type="datetime" [titleIsTheData]="false" [data]="serviceSheet.checkInScheduledDt" (focusOut)="estimatedCheckinDateChange($event)" title="{{ 'CHECK_IN_SCHEDULED_AT' | translate }}"></app-inline-edit>
                      <app-inline-edit *ngIf="serviceSheet.checkInStatus == 'ret'" type="datetime" [titleIsTheData]="false" [data]="serviceSheet.checkInScheduledDt" (focusOut)="estimatedCheckinDateChange($event)" title="{{ 'PICKUP_SCHEDULED_AT' | translate }}"></app-inline-edit>
                    </p>

                    <mat-divider class=""></mat-divider>

                    <!-- BSS info -->
                    <div class="p2" fxLayout="column">
                      <p>{{ 'SERVICE_SHEET_ID' | translate }}: {{ serviceSheet.shortPrettyId }}</p>
                      <ng-container *ngIf="serviceSheet.budgetStatus == 'don' || serviceSheet.budgetStatus == 'app'">
                        <app-invoice-controls
                          [invoiceSeries]="serviceSheet.invoiceSeries"
                          [invoiceNumber]="serviceSheet.invoiceNumber"
                          [invoiceDate]="serviceSheet.invoiceDate"
                          (invoiceSeriesChange)="changeInvoiceSeries($event)"
                          (generateInvoiceEvent)="generateInvoice()">
                        </app-invoice-controls>
                      </ng-container>

                        <mat-form-field *ngIf="mechanics" class="mt3i" appearance="outline">
                            <mat-label *ngIf="!serviceSheet.assignedTo">{{ 'ASSIGN_TO' | translate }}</mat-label>
                            <mat-label *ngIf="serviceSheet.assignedTo">{{ 'ASSIGNED_TO' | translate }}</mat-label>
                            <mat-select (selectionChange)="assignTo($event)" [(value)]="serviceSheet.assignedTo">
                                <mat-option *ngFor="let mechanic of mechanics" [value]="mechanic.id">
                                {{mechanic.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="mt1" appearance="outline">
                            <mat-label>{{ 'PRIORITY' | translate }}</mat-label>
                            <mat-select (selectionChange)="changePriority($event)" [(value)]="serviceSheet.priority">
                                <mat-option *ngFor="let priority_eng_literal of prioritiesStates | enumStrToArray" [value]="prioritiesStates[priority_eng_literal]">
                                    <!-- <mat-icon color="warn">stop</mat-icon> -->
                                    <!-- <span>↑</span> -->
                                    <!-- https://stackoverflow.com/questions/49746997/angular-material-maticon-in-matselect -->
                                    <!-- https://material.angular.io/components/select/api#MatSelectTrigger -->
                                    <!-- https://fonts.google.com/icons -->
                                    {{ "PRIORITY_" + priority_eng_literal | uppercase | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="mt1" appearance="outline">
                            <mat-label>{{ 'BUDGET_STATUS' | translate }}</mat-label>
                            <mat-select (selectionChange)="changeBudgetStatus($event)" [(value)]="serviceSheet.budgetStatus">
                                <mat-option *ngFor="let status of budgetStatusStates | enumStrToArray" [value]="budgetStatusStates[status]">
                                {{ status | uppercase | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- <span>{{ 'ENTRY_DATE' | translate }}: {{ serviceSheet.createdAt | date:'d MMM y'}}</span> -->
                        <span>{{ 'ENTRY_DATE' | translate }}: {{ serviceSheet.createdAt | date }}</span>

                        <!-- TODO: remove app-inline-here, and just use directly material calendar date picker -->
                        <ng-container *ngIf="serviceSheet.estimatedDeliveryDt">
                            <span [satPopoverAnchor]="pd" (click)="pd.toggle()" [ngClass]="{'text-danger': isPastDate(serviceSheet.checkInScheduledDt) && !serviceSheet.closed}">{{ 'ESTIMATED_DELIVERY_DATE' | translate }}: {{ serviceSheet.estimatedDeliveryDt | date }}</span>
                        </ng-container>
                        <ng-container *ngIf="!serviceSheet.estimatedDeliveryDt">
                            <span [satPopoverAnchor]="pd" (click)="pd.toggle()" class="muted">{{ 'ADD_ESTIMATED_DELIVERY' | translate }}</span>
                        </ng-container>

                        <sat-popover #pd hasBackdrop xAlign="start" yAlign="start" (closed)="deliveryDateChange($event)">
                            <app-edit-input type="datetime" [value]="serviceSheet.estimatedDeliveryDt" title="{{ 'ESTIMATED_DELIVERY_DATE' | translate }}"></app-edit-input>
                        </sat-popover>

                        <button mat-stroked-button class="mt3"
                            [disabled]="serviceSheet.closed || unfinishedTasksCount == 0 || finishedTasksCount > 0 || owner?.email.length < 2"
                            color="accent" (click)="sendBudgetClient()">{{ 'SEND_BUDGET_CLIENT' | translate
                            }}</button>
                        <button mat-stroked-button *ngIf="serviceSheet?.closed"
                                class="mt1"
                            color="accent" (click)="reopenServiceSheet(); trackClassicButtonsUsed()">{{ 'REOPEN_SERVICE_SHEET' | translate
                            }}</button>
                        <button mat-stroked-button *ngIf="!serviceSheet?.closed" class="mt1" [disabled]="unfinishedTasksCount > 0"
                            color="accent" (click)="closeServiceSheet(); trackClassicButtonsUsed()">{{ 'CLOSE_SERVICE_SHEET' | translate
                            }}</button>

                        <button mat-stroked-button
                                class="mt1" *ngIf="serviceSheet.checkInStatus !== 'cou'"
                            [disabled]="(!serviceSheet.closed) || !clientHasContactData()"
                            color="accent" (click)="notifyClient(); trackClassicButtonsUsed()">
                            <ng-container *ngIf="!serviceSheet.notifiedClient">
                                {{ 'NOTIFY_CLIENT_BIKE_READY' | translate }}
                            </ng-container>
                            <ng-container *ngIf="serviceSheet.notifiedClient">
                                {{ 'NOTIFY_CLIENT_BIKE_READY_AGAIN' | translate }}
                            </ng-container>
                        </button>

                        <button color="accent" mat-stroked-button
                                *ngIf="serviceSheet?.closed"
                                class="mt1"
                                [matMenuTriggerFor]="menu_invoice_actions">
                                {{ 'INVOICE' | translate }}
                        </button>

                        <!-- TODO: remove this button, as we have the button toggles on top to mark bike checked in -->
                        <button mat-stroked-button
                                class="mt1" *ngIf="serviceSheet.closed && serviceSheet.checkInStatus == 'cin'"
                            color="accent" (click)="checkOutBike(); trackClassicButtonsUsed()">
                                {{ 'MARK_CHECK_OUT_BIKE' | translate }}
                        </button>

                        <button mat-stroked-button
                                class="mt1"
                                color="accent" [matMenuTriggerFor]="menu_receipt_actions">
                                {{ 'RECEIPT' | translate }}
                        </button>

                        <button mat-stroked-button
                                *ngIf="!serviceSheet.invoiceNumber"
                                class="mt1"
                                color="accent" (click)="showVatFields()">
                                {{ 'SHOW_VAT' | translate }}
                        </button>
                        <button mat-button color="primary" [matMenuTriggerFor]="menu_more_bss_actions">
                            <!-- <mat-icon>arrow_drop_down</mat-icon> -->
                            {{ 'MORE_ACTIONS' | translate | uppercase }}
                        </button>

                    </div>

                    <mat-divider class="pb2"></mat-divider>

                    <!-- Client info -->
                    <div class="p2" fxLayout="column">
                        <span class="text-light pb1">{{ 'OWNER' | translate }}</span>

                        <span [routerLink]="['/clients/', serviceSheet.owner]" class="link-action pb1">{{ serviceSheet.ownerName }}</span>
                        <span *ngIf="serviceSheet.ownerMail" class="pb1">{{ serviceSheet.ownerMail }}</span>
                        <span *ngIf="serviceSheet.ownerPhone" class="pb1">{{ serviceSheet.ownerPhone }}<img *ngIf="!isFully" class="mx1 link-action" aria-hidden="false" aria-label="WhatsApp" (click)="openWhatsApp()" src="/assets/images/icons/whatsapp_black_24dp.svg"/>
                        <mat-icon *ngIf="!isFully" class="link-action" aria-hidden="false" aria-label="Call" (click)="openCall()">call</mat-icon></span>

                    </div>

                    <mat-divider class="pb2"></mat-divider>
                    <div class="p2" fxLayout="column">
                        <span *ngIf="serviceSheet.openedByName" fxLayout="row" fxLayoutAlign="start center">
                            <mat-icon>engineering</mat-icon> {{ 'OPENED_BY' | translate }}: {{ serviceSheet.openedByName }}
                        </span>
                    </div>
                </div>

                <!-- Right/bottom info pane -->
                <!-- pb4 makes space for the fab when the screen is small -->
              <div fxFlex.gt-sm="70">

                <div fxLayout="column-reverse"
                     style="margin-bottom: 8px"
                     fxLayout.gt-md="row"
                     fxLayoutAlign.gt-md="space-between center"
                     fxLayoutGap.gt-md="4px">

                  <app-workflow-buttons
                    fxLayoutAlign="start center"
                    [steps]="steps"
                    [nextStepAction]="nextStepAction"
                    (stepChange)="onStepChange($event)"
                    style="margin-top: 8px"
                  ></app-workflow-buttons>

<!--                  TODO: extract this to some component -->
                  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="14px">
                    <img [matMenuTriggerFor]="sendMenuWhatsapp" alt="Send whatsapp" class="mr2 pointer" style="width: 24px;" src="/assets/images/icons/whatsapp_fill.svg"/>
                    <mat-icon [matMenuTriggerFor]="sendMenuMail" class="pointer">mail</mat-icon>
                    <mat-icon [matMenuTriggerFor]="printMenu" class="pointer">print</mat-icon>
                    <mat-icon [matMenuTriggerFor]="downloadPDFMenu" class="pointer">picture_as_pdf</mat-icon>
                    <mat-icon [matMenuTriggerFor]="moreBssOptsMenu" class="pointer">more_vert</mat-icon>
                  </div>
                </div>

                <!-- tabs -->
                <mat-tab-group mat-stretch-tabs>
                  <!-- TODO: improve label -->
                  <mat-tab label="{{ 'SERVICE_SHEET' | translate }}">
                    <h3 fxLayoutAlign="center" class="mb0i pt1" style="background-color: white; min-width: 800px;">{{ "TASKS" | translate }}</h3>
                    <div class="mb3" style="min-width: 800px;">
                      <app-bss-task-list [showVat]="serviceSheet.invoiceNumber != null" [showFooter]="false" (itemsUpdated)="onProductAdded()" (itemsChange)="tasksChanged($event)" [parent]="serviceSheet"></app-bss-task-list>
                    </div>

                    <h3 fxLayoutAlign="center" class="mb0i pt1" style="background-color: white; min-width: 800px;">{{ "PRODUCTS" | translate }}</h3>
                    <div style="min-width: 800px;">
                      <app-pos-order-item-list [showVat]="serviceSheet.invoiceNumber != null" [showFooter]="false" parentApiContentTypeName="bikeservicesheet" (itemsUpdated)="onProductAdded()" (itemsChange)="onProductAdded()" [parent]="serviceSheet"></app-pos-order-item-list>
                    </div>

                    <div class="mt3" style="background-color: white" fxLayout="row" fxLayoutAlign="space-between">
                      <h3 class="p2 m0i">{{ "TOTAL" | translate }} {{ "TASKS" | translate }} + {{ "PRODUCTS" | translate }}:</h3>
                      <h3 class="p2 m0i">{{ bssTotalCost | currency: currencyCode : 'symbol-narrow' }}</h3>
                    </div>

                    <app-payment-status-card #paymentCard
                                             [billableItem]="serviceSheet"
                                             (billableItemChange)="onPaymentStatusChange($event)">
                    </app-payment-status-card>

                  </mat-tab>

                  <mat-tab label="{{ 'NOTES' | translate }} {{ (serviceSheet.generalNotesPublic || serviceSheet.generalNotesPrivate) ? '*' : '' }}">

                    <app-bss-notes [parent]="serviceSheet"></app-bss-notes>

                  </mat-tab>

                  <mat-tab label="{{ 'CTX_SERVICE_SHEETS.PICTURES' | translate }}">

                    <app-bss-files [parent]="serviceSheet"></app-bss-files>

                  </mat-tab>

                </mat-tab-group>
              </div>
            </div>

        </ng-container>
        <ng-template #loading>loading...</ng-template>
    </ng-container>

</div>

<mat-menu #menu_more_bss_actions="matMenu">
  <button mat-menu-item (click)="print('a4'); trackClassicSendPrintButtonsUsed()">
    <mat-icon>print</mat-icon> {{ 'PRINT' | translate }}
  </button>
  <button mat-menu-item (click)="print('58mm'); trackClassicSendPrintButtonsUsed()">
    <mat-icon>print</mat-icon> {{ 'PRINT' | translate }} 80mm
  </button>
  <button *ngIf="!isFully" mat-menu-item (click)="downloadPDF(); trackClassicSendPrintButtonsUsed()">
    <mat-icon>picture_as_pdf</mat-icon> {{ 'DOWNLOAD_PDF' | translate }}
  </button>
  <button mat-menu-item (click)="deleteBSS(); trackClassicSendPrintButtonsUsed()">
    <mat-icon color="warn">delete</mat-icon> {{ 'DELETE_BSS' | translate }}
  </button>
</mat-menu>

<mat-menu #menu_invoice_actions="matMenu">
  <button mat-menu-item (click)="printInvoice(); trackClassicSendPrintButtonsUsed()">
    <mat-icon>print</mat-icon> {{ 'PRINT_INVOICE' | translate }}
  </button>
  <button mat-menu-item (click)="sendInvoice(); trackClassicSendPrintButtonsUsed()">
    <mat-icon>email</mat-icon> {{ 'SEND_INVOICE' | translate }}
  </button>
</mat-menu>

<mat-menu #menu_receipt_actions="matMenu">
  <button mat-menu-item (click)="sendReceipt(); trackClassicSendPrintButtonsUsed()" [disabled]="this.owner && this.owner.email == ''">
    <mat-icon>mail</mat-icon> {{ 'SEND_RECEIPT_EMAIL' | translate }}
  </button>
  <button mat-menu-item (click)="printReceipt(); trackClassicSendPrintButtonsUsed()">
    <mat-icon>print</mat-icon> {{ 'PRINT_RECEIPT' | translate }}
  </button>
</mat-menu>

<mat-menu #sendMenuWhatsapp="matMenu">
<!--  TODO: can we merge this menu with the sendMenu to avoid so many duplicate code? -->
  <button mat-menu-item
          (click)="sendReceiptWhatsApp(); trackNewSendPrintButtonsUsed()"
          [disabled]="serviceSheet && serviceSheet.ownerPhone?.length < 3">
    {{ 'RECEIPT' | translate }}
    <ng-container *ngIf="serviceSheet && serviceSheet.ownerPhone?.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_PHONE" | translate }}</small></ng-container>
  </button>

  <button mat-menu-item
          (click)="sendTicketWhatsApp(); trackNewSendPrintButtonsUsed()"
          [disabled]="serviceSheet && (serviceSheet.ownerPhone?.length < 3 || !serviceSheet.closed)">
    {{ 'TICKET' | translate }}
    <ng-container *ngIf="serviceSheet && serviceSheet.ownerPhone?.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_PHONE" | translate }}</small></ng-container>
    <ng-container *ngIf="serviceSheet && (serviceSheet.ownerPhone?.length < 3 || !serviceSheet.closed)"><br><small>{{ "SERVICE_SHEET_NOT_CLOSED" | translate }}</small></ng-container>
  </button>

<!--  <button mat-menu-item (click)="sendTicketClient()">{{ 'TICKET' | translate }}</button>-->

  <button mat-menu-item
          (click)="sendBudgetClientWhatsApp(); trackNewSendPrintButtonsUsed()"
          [disabled]="(serviceSheet && serviceSheet.closed) || (serviceSheet && serviceSheet.ownerPhone?.length < 3)">
    {{ 'BUDGET' | translate }}
    <ng-container *ngIf="serviceSheet && serviceSheet.closed"><br><small>{{ "SERVICE_SHEET_CLOSED" | translate }}</small></ng-container>
    <ng-container *ngIf="serviceSheet && serviceSheet.ownerPhone?.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_PHONE" | translate }}</small></ng-container>
  </button>
  <button mat-menu-item
          (click)="sendInvoiceWhatsApp(); trackNewSendPrintButtonsUsed()"
          [disabled]="(serviceSheet && !serviceSheet.closed) || (serviceSheet && serviceSheet.ownerPhone.length < 3)">
    {{ 'INVOICE' | translate }}
    <ng-container *ngIf="serviceSheet && !serviceSheet.closed"><br><small>{{ "SERVICE_SHEET_NOT_CLOSED" | translate }}</small></ng-container>
    <ng-container *ngIf="serviceSheet && serviceSheet.ownerPhone?.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_PHONE" | translate }}</small></ng-container>
  </button>
  <button mat-menu-item
          (click)="sendBikeReadyWhatsApp(); trackNewSendPrintButtonsUsed()"
          [disabled]="(serviceSheet && !serviceSheet.closed) || (serviceSheet && serviceSheet.ownerMail.length < 3)">
    {{ 'NOTIFY_CLIENT_BIKE_READY' | translate }}
    <ng-container *ngIf="serviceSheet && !serviceSheet.closed"><br><small>{{ "SERVICE_SHEET_NOT_CLOSED" | translate }}</small></ng-container>
    <ng-container *ngIf="serviceSheet && serviceSheet.ownerPhone?.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_PHONE" | translate }}</small></ng-container>
  </button>
</mat-menu>

<mat-menu #sendMenuMail="matMenu">
  <button mat-menu-item
          (click)="sendReceipt(); trackNewSendPrintButtonsUsed()"
          [disabled]="serviceSheet && serviceSheet.ownerMail.length < 3">
    {{ 'RECEIPT' | translate }}
    <ng-container *ngIf="serviceSheet && serviceSheet.ownerMail.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_MAIL" | translate }}</small></ng-container>
  </button>

  <button mat-menu-item
          (click)="sendTicket(); trackNewSendPrintButtonsUsed()"
          [disabled]="serviceSheet && (serviceSheet.ownerMail.length < 3 || !serviceSheet.closed)">
    {{ 'TICKET' | translate }}
    <ng-container *ngIf="serviceSheet && serviceSheet.ownerMail.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_MAIL" | translate }}</small></ng-container>
    <ng-container *ngIf="serviceSheet && (serviceSheet.ownerMail.length < 3 || !serviceSheet.closed)"><br><small>{{ "SERVICE_SHEET_NOT_CLOSED" | translate }}</small></ng-container>
  </button>

<!--  <button mat-menu-item (click)="sendTicketClient()">{{ 'TICKET' | translate }}</button>-->

  <button mat-menu-item
          (click)="sendBudgetClient(); trackNewSendPrintButtonsUsed()"
          [disabled]="(serviceSheet && serviceSheet.closed) || (serviceSheet && serviceSheet.ownerMail.length < 3)">
    {{ 'BUDGET' | translate }}
    <ng-container *ngIf="serviceSheet && serviceSheet.closed"><br><small>{{ "SERVICE_SHEET_CLOSED" | translate }}</small></ng-container>
    <ng-container *ngIf="serviceSheet && serviceSheet.ownerMail.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_MAIL" | translate }}</small></ng-container>
  </button>
  <button mat-menu-item
          (click)="sendInvoice(); trackNewSendPrintButtonsUsed()"
          [disabled]="(serviceSheet && !serviceSheet.closed) || (serviceSheet && serviceSheet.ownerMail.length < 3)">
    {{ 'INVOICE' | translate }}
    <ng-container *ngIf="serviceSheet && !serviceSheet.closed"><br><small>{{ "SERVICE_SHEET_NOT_CLOSED" | translate }}</small></ng-container>
    <ng-container *ngIf="serviceSheet && serviceSheet.ownerMail.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_MAIL" | translate }}</small></ng-container>
  </button>
</mat-menu>

<mat-menu #printMenu="matMenu">
  <button mat-menu-item (click)="printReceipt(); trackNewSendPrintButtonsUsed()">{{ 'RECEIPT' | translate }}</button>
  <button mat-menu-item (click)="print('58mm'); trackNewSendPrintButtonsUsed()">{{ 'TICKET' | translate }}</button>
<!--  <button mat-menu-item (click)="printBudget()">{{ 'BUDGET' | translate }}</button>-->
  <button mat-menu-item (click)="printInvoice(); trackNewSendPrintButtonsUsed()">{{ 'INVOICE' | translate }}</button>
</mat-menu>

<mat-menu #downloadPDFMenu="matMenu">
<!--  <button mat-menu-item (click)="downloadPDF('ticket'); trackNewSendPrintButtonsUsed()">{{ 'TICKET' | translate }}</button>-->
  <button mat-menu-item
          (click)="downloadPDF('budget'); trackNewSendPrintButtonsUsed()"
          [disabled]="(serviceSheet && serviceSheet.closed)">
    {{ 'BUDGET' | translate }}
    <ng-container *ngIf="serviceSheet && serviceSheet.closed"><br><small>{{ "SERVICE_SHEET_CLOSED" | translate }}</small></ng-container>
  </button>
  <button mat-menu-item
          (click)="downloadPDF('invoice'); trackNewSendPrintButtonsUsed()"
          [disabled]="(!serviceSheet?.closed)">
    {{ 'INVOICE' | translate }}
    <ng-container *ngIf="!serviceSheet?.closed"><br><small>{{ "SERVICE_SHEET_NOT_CLOSED" | translate }}</small></ng-container>
  </button>
</mat-menu>

<mat-menu #moreBssOptsMenu="matMenu">
  <button mat-menu-item (click)="deleteBSS(); trackNewSendPrintButtonsUsed()">
    <mat-icon color="warn">delete</mat-icon>{{ 'DELETE_BSS' | translate }}
  </button>
</mat-menu>
